import { database } from "./../firebase";
const db = database.ref("/games");

const getAll = () => {
  return db;
};

const getAllForGame = (gameId) => {
  return database.ref(`/games/${gameId}`);
};

const create = (data) => {
  return db.push(data);
};

const update = (key, data) => {
  return db.child(key).update(data);
};

const remove = (key) => {
  return db.child(key).remove();
};

const removeAll = () => {
  return db.remove();
};

export default {
  getAll,
  getAllForGame,
  create,
  update,
  remove,
  removeAll,
};
