import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { AuthContext } from "../auth";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import ListSubheader from "@material-ui/core/ListSubheader";
import jeziki from "../jeziki";
import "flag-icon-css/css/flag-icon.min.css";
import Avatar from "@mui/material/Avatar";
import iconSI from "./../Logotipi/si.png";
import iconHU from "./../Logotipi/hu.png";
import iconEN from "./../Logotipi/us.png";
import iconDE from "./../Logotipi/de.png";

const languageMap = {
  si: { short: "si", label: "Slovenščina", dir: "ltr", active: false },
  hu: { short: "hu", label: "Magyar", dir: "ltr", active: true },
  en: { short: "en", label: "English", dir: "ltr", active: false },
  de: { short: "de", label: "Deutsch", dir: "ltr", active: false },
};

const LanguageSelect = () => {
  const selected = localStorage.getItem("i18nextLng") || "en";

  const [menuAnchor, setMenuAnchor] = React.useState(null);
  React.useEffect(() => {
    document.body.dir = languageMap[selected].dir;
  }, [menuAnchor, selected]);
  const authContext = React.useContext(AuthContext);
  const handleChange = (panel) => {
    authContext.setLanguage(panel);
    setMenuAnchor(null);
  };
  let activeIcon;
  if (authContext.language === "si") activeIcon = iconSI;
  if (authContext.language === "hu") activeIcon = iconHU;
  if (authContext.language === "en") activeIcon = iconEN;
  if (authContext.language === "de") activeIcon = iconDE;
  return (
    <div className="d-flex justify-content-end align-items-center language-select-root">
      <Button onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}>
        <Avatar alt="hu" src={activeIcon} style={{ marginRight: "5px" }} sx={{ width: 24, height: 24 }} />
        {jeziki[authContext.language].ime}
        <ArrowDropDown fontSize="small" />
      </Button>
      <Popover
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div>
          <List>
            <ListItem button key={"si"} onClick={() => handleChange("si")}>
              <ListItemIcon sx={{ fontSize: 20 }}>
                <Avatar alt="si" src={iconSI} />
              </ListItemIcon>
              <ListItemText
                sx={{ my: 0 }}
                primary={"Slovenščina"}
                primaryTypographyProps={{
                  fontSize: 20,
                  fontWeight: "medium",
                  letterSpacing: 0,
                }}
              />
            </ListItem>
            <ListItem button key={"hu"} onClick={() => handleChange("hu")}>
              <ListItemIcon sx={{ fontSize: 20 }}>
                <Avatar alt="hu" src={iconHU} />
              </ListItemIcon>
              <ListItemText
                sx={{ my: 0 }}
                primary={"Magyar"}
                primaryTypographyProps={{
                  fontSize: 20,
                  fontWeight: "medium",
                  letterSpacing: 0,
                }}
              />
            </ListItem>
            <ListItem button key={"en"} onClick={() => handleChange("en")}>
              <ListItemIcon sx={{ fontSize: 20 }}>
                <Avatar alt="en" src={iconEN} />
              </ListItemIcon>
              <ListItemText
                sx={{ my: 0 }}
                primary={"English"}
                primaryTypographyProps={{
                  fontSize: 20,
                  fontWeight: "medium",
                  letterSpacing: 0,
                }}
              />
            </ListItem>
            <ListItem button key={"de"} onClick={() => handleChange("de")}>
              <ListItemIcon sx={{ fontSize: 20 }}>
                <Avatar alt="de" src={iconDE} />
              </ListItemIcon>
              <ListItemText
                sx={{ my: 0 }}
                primary={"Deutsch"}
                primaryTypographyProps={{
                  fontSize: 20,
                  fontWeight: "medium",
                  letterSpacing: 0,
                }}
              />
            </ListItem>
          </List>
        </div>
      </Popover>
    </div>
  );
};

export default LanguageSelect;
