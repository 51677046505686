import React from "react";
import { AuthContext } from "../auth";
import firebase, { auth } from "../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { Grid, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SignIn from "./../components/GameOne/SignIn";
import jeziki from "../jeziki";
import "flag-icon-css/css/flag-icon.min.css";
export default function GamePicker() {
  const history = useHistory();

  const authContext = React.useContext(AuthContext);
  const provider = new firebase.auth.GoogleAuthProvider();
  function handleClick() {
    auth.signInWithPopup(provider).then(function (result) {
      authContext.setUser(result.user);
      history.push("/game-1-options");
    });
  }
  let [expanded, setExpanded] = React.useState(authContext.language);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    authContext.setLanguage(panel);
  };
  React.useEffect(() => {
    if (authContext.authenticated) {
      history.push("/game-1-options");
    }
  }, [authContext.authenticated, history]);
  expanded = authContext.language;
  return (
    <>
      <Grid container direction="row" justifyContent="center" alignItems="center" style={{ height: "80vh" }}>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} style={{ padding: "5px" }}>
          <Accordion expanded={expanded === "si"} onChange={handleChange("si")}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header" style={{ width: "100%" }}>
              <span className="flag-icon flag-icon-si" style={{ marginLeft: "10px", marginRight: "10px" }}></span>
              <Typography sx={{ width: "50%", flexShrink: 0 }}>{jeziki.si.imeIgre}</Typography>
              <Typography sx={{ color: "text.secondary" }}>{jeziki.si.tipIgre}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{jeziki.si.navodila}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === "hu"} onChange={handleChange("hu")}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header" style={{ width: "100%" }}>
              <span className="flag-icon flag-icon-hu" style={{ marginLeft: "10px", marginRight: "10px" }}></span>
              <Typography sx={{ width: "50%", flexShrink: 0 }}>{jeziki.hu.imeIgre}</Typography>
              <Typography sx={{ color: "text.secondary" }}>{jeziki.hu.tipIgre}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{jeziki.hu.navodila}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === "en"} onChange={handleChange("en")}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header" style={{ width: "100%" }}>
              <span className="flag-icon flag-icon-us" style={{ marginLeft: "10px", marginRight: "10px" }}></span>
              <span className="fp  us"></span>
              <Typography sx={{ width: "50%", flexShrink: 0 }}>{jeziki.en.imeIgre}</Typography>
              <Typography sx={{ color: "text.secondary" }}>{jeziki.en.tipIgre}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{jeziki.en.navodila}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === "de"} onChange={handleChange("de")}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header" style={{ width: "100%" }}>
              <span className="flag-icon flag-icon-de" style={{ marginLeft: "10px", marginRight: "10px" }}></span>
              <Typography sx={{ width: "50%", flexShrink: 0 }}>{jeziki.de.imeIgre}</Typography>
              <Typography sx={{ color: "text.secondary" }}>{jeziki.de.tipIgre}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{jeziki.de.navodila}</Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} style={{ padding: "5px" }}>
          <SignIn></SignIn>
        </Grid>
      </Grid>
      <Container sx={{ marginBottom: { xs: "200px", lg: "0px" } }}></Container>
    </>
  );
}
