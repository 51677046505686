import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
const firebaseConfig = {
  apiKey: "AIzaSyDtFjEUijmmcl29pxOeOtQZMe3sBd3CQDc",
  authDomain: "pmsnsspomin.firebaseapp.com",
  databaseURL: "https://pmsnsspomin-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "pmsnsspomin",
  storageBucket: "pmsnsspomin.appspot.com",
  messagingSenderId: "727243731140",
  appId: "1:727243731140:web:93b864c26ab9781033d991",
  measurementId: "G-EKG9HZ6T6H",
};
firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const db = firebase.firestore();
export const database = firebase.database();

export default firebase;
