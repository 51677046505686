import React from "react";
import { AuthContext } from "../auth";
import { auth } from "../firebase";
import { useHistory } from "react-router-dom";
import { Avatar, Card, Button } from "@material-ui/core";
import LogoutIcon from "@mui/icons-material/Logout";
import jeziki from "./../jeziki";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import MusicOffIcon from "@mui/icons-material/MusicOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
export default function UserInfo() {
  const history = useHistory();
  const authContext = React.useContext(AuthContext);
  React.useEffect(() => {
    if (!authContext.authenticated) {
      history.push("/");
    }
  }, [authContext.authenticated, history]);

  return authContext.authenticated ? <UserCard authContext={authContext}></UserCard> : <></>;
}

const useAudio = (url) => {
  const [audio] = React.useState(new Audio(url));
  const [playing, setPlaying] = React.useState(false);

  const toggle = () => setPlaying(!playing);

  React.useEffect(() => {
    audio.volume = 0.1;
    playing ? audio.play() : audio.pause();
  }, [playing, audio]);

  React.useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  return [playing, toggle];
};

function UserCard({ authContext }) {
  const history = useHistory();
  const [playing, toggle] = useAudio(process.env.PUBLIC_URL + "/zvoki/bg.mp3");
  /* const audio = new Audio();
  const [music, setMusic] = React.useState(false);
  audio.src = process.env.PUBLIC_URL + "/zvoki/bg.mp3";
  */
  const handleSignout = () => {
    history.push("/");
    auth.signOut().then(() => {});
  };
  const handleSounds = () => {
    console.log("handles sounds");
    authContext.setSounds(!authContext.sounds);
  };
  /*
  React.useEffect(() => {
    if (!music) {
      audio.play();
    } else {
      console.log("pausing");
      audio.pause();
      audio.currentTime = 0;
    }
  }, [music, audio]);
  const playSong = () => {
    setMusic(!music);
    console.log(music);
  }; */

  return (
    <Card elevation={0} className="user-info" style={{ marginRight: "2vw", minWidth: "190px" }}>
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <Avatar alt={authContext.user.displayName} src={authContext.user.photoURL ? authContext.user.photoURL : ""} />
      </Box>
      <h3>
        {authContext.user.displayName ? authContext.user.displayName : authContext.user.email.substring(0, authContext.user.email.lastIndexOf("@"))}
      </h3>
      <IconButton aria-label="delete" style={{ marginRight: "0px" }} onClick={handleSounds}>
        {authContext.sounds ? <VolumeUpIcon /> : <VolumeOffIcon />}
      </IconButton>
      <IconButton aria-label="delete" style={{ marginRight: "0px" }} onClick={toggle}>
        {playing ? <MusicNoteIcon /> : <MusicOffIcon />}
      </IconButton>
      <IconButton variant="contained" size="small" onClick={handleSignout} style={{ backgroundColor: "#f99c3f", marginRight: "0px" }}>
        <LogoutIcon />
      </IconButton>
    </Card>
  );
}
