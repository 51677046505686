import React from "react";
import "./card.css";
import { Card, Box } from "@material-ui/core";

export default function GameCard({ data, flipped, flip, disabled, length, solved, cardDimensions }) {
  let cardDim = cardDimensions + "px";
  return (
    <Box component="div" sx={{ visibility: solved ? "hidden" : "visible" }}>
      <div className={`flip-container ${flipped ? "flipped" : ""}`}>
        <div
          className="flipper"
          style={length === 12 ? { width: cardDim, height: cardDim } : { width: cardDim, height: cardDim }}
          onClick={() => (disabled ? null : flip(data.id))}
        >
          <Card className="front" invisible="true" sx={{ visibility: "hidden" }} style={{}}>
            <img src={"https://pmsnsspomin.web.app/" + data.flag} alt={data.name} style={{ width: "70%" }}></img>
          </Card>
          <Card /* className="back" */>
            <img src={"https://pmsnsspomin.web.app/slike/zadaj.jpg"} alt={"slika"} style={{ width: "100%" }}></img>
          </Card>
        </div>
      </div>
    </Box>
  );
}
