import * as React from "react";
import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import GoogleIcon from "@mui/icons-material/Google";
import EmailIcon from "@mui/icons-material/Email";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { useHistory } from "react-router-dom";
import { AuthContext } from "./../../auth";
import firebase, { auth } from "../../firebase";
import jeziki from "../../jeziki";
import Alert from "@mui/material/Alert";

const theme = createTheme({});

export default function SignIn() {
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const authContext = React.useContext(AuthContext);
  const provider = new firebase.auth.GoogleAuthProvider();
  const providerEmail = new firebase.auth.EmailAuthProvider();

  function handleLoginClickGoogle() {
    auth.signInWithPopup(provider).then(function (result) {
      authContext.setUser(result.user);
      history.push("/game-1-options");
    });
  }
  async function handleLoginClickEmail(e) {
    e.preventDefault();
    setError("");

    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailValue) {
      setError(jeziki[authContext.language].errorFaieldToCreateAccount + jeziki[authContext.language].errorEmailEmpty);
      return;
    }
    if (!re.test(emailValue)) {
      setError(jeziki[authContext.language].errorFaieldToCreateAccount + jeziki[authContext.language].errorEmailNotValid);
      return;
    }
    if (!passwordValue) {
      setError(jeziki[authContext.language].errorFaieldToCreateAccount + jeziki[authContext.language].errorPasswordEmpty);
      return;
    }
    if (passwordValue.length < 6) {
      setError(jeziki[authContext.language].errorFaieldToCreateAccount + jeziki[authContext.language].errorPasswordShort);
      return;
    }
    try {
      setError("");
      setLoading(true);
      await auth.signInWithEmailAndPassword(emailValue, passwordValue).then(function (userCredential) {
        authContext.setUser(userCredential.user);
        history.push("/game-1-options");
      });

      history.push("/");
    } catch (error) {
      if (error.toString().trim().includes("auth/user-not-found")) {
        setError(jeziki[authContext.language].errorFaieldToCreateAccount + jeziki[authContext.language].errorUserNotExist);
      } else {
        setError(jeziki[authContext.language].errorFaieldToCreateAccount + error);
      }
    }

    setLoading(false);
  }
  async function handlePasswordReset(e) {
    e.preventDefault();
    setError("");

    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailValue) {
      setError(jeziki[authContext.language].errorResetPassword + jeziki[authContext.language].errorEmailEmpty);
      return;
    }
    if (!re.test(emailValue)) {
      setError(jeziki[authContext.language].errorResetPassword + jeziki[authContext.language].errorEmailNotValid);
      return;
    }
    try {
      setError("");
      setLoading(true);
      await auth.sendPasswordResetEmail(emailValue).then(alert(jeziki[authContext.language].emailSent));
      //history.push("/");
    } catch (error) {
      if (error.toString().trim().includes("auth/user-not-found")) {
        setError(jeziki[authContext.language].errorResetPassword + jeziki[authContext.language].errorUserNotExist);
      } else {
        setError(jeziki[authContext.language].errorResetPassword + error);
      }
    }

    setLoading(false);
  }
  async function handleCreateUserWithEmail(e) {
    e.preventDefault();
    setError("");

    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailValue) {
      setError(jeziki[authContext.language].errorFaieldToCreateAccount + jeziki[authContext.language].errorEmailEmpty);
      return;
    }
    if (!re.test(emailValue)) {
      setError(jeziki[authContext.language].errorFaieldToCreateAccount + jeziki[authContext.language].errorEmailNotValid);
      return;
    }
    if (!passwordValue) {
      setError(jeziki[authContext.language].errorFaieldToCreateAccount + jeziki[authContext.language].errorPasswordEmpty);
      return;
    }
    if (passwordValue.length < 6) {
      setError(jeziki[authContext.language].errorFaieldToCreateAccount + jeziki[authContext.language].errorPasswordShort);
      return;
    }
    try {
      setError("");
      setLoading(true);
      await auth.createUserWithEmailAndPassword(emailValue, passwordValue).then((userCredential) => {
        authContext.setUser(userCredential.user);
        history.push("/game-1-options");
      });
      history.push("/");
    } catch (error) {
      setError(jeziki[authContext.language].errorFaieldToCreateAccount + error);
    }

    setLoading(false);
    /* auth
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Signed in
        var user = userCredential.user;
        
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        
      }); */
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
  };
  const [haveAccount, setHaveAccount] = React.useState(true);
  React.useEffect(() => {
    if (authContext.authenticated) {
      history.push("/game-1-options");
    }
  }, [authContext.authenticated, history]);
  return (
    <ThemeProvider theme={theme}>
      {haveAccount ? (
        <Container
          component="main"
          maxWidth="xs"
          style={{ backgroundColor: "white", opacity: 0.95 }}
          sx={{ marginBottom: { xs: "60px", md: "0px" } }}
        >
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }} style={{ backgroundColor: "#3fb550" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {jeziki[authContext.language].textVpis}
            </Typography>
            {error && <Alert severity="error">{error}</Alert>}
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label={jeziki[authContext.language].textEmail}
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(value) => {
                  setEmailValue(value.target.value);
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={jeziki[authContext.language].textPassword}
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(value) => {
                  setPasswordValue(value.target.value);
                }}
              />
              <Button
                style={{ backgroundColor: "#3fb550" }}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleLoginClickEmail}
                startIcon={<EmailIcon />}
              >
                {jeziki[authContext.language].textVpis}
              </Button>
              <Grid container>
                <Grid item sx={{ width: "50%" }}>
                  <Link href="#" variant="body2" onClick={handlePasswordReset}>
                    {jeziki[authContext.language].textPozabljenoGeslo}
                  </Link>
                </Grid>
                <Grid item sx={{ width: "50%" }}>
                  <Link href="#" variant="body2" onClick={() => setHaveAccount(false)}>
                    {jeziki[authContext.language].textUstvariNovProfil}
                  </Link>
                </Grid>
              </Grid>
              <Divider style={{ marginTop: "7px", marginBottom: "7px" }}>{jeziki[authContext.language].textAli}</Divider>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleLoginClickGoogle}
                startIcon={<GoogleIcon />}
                style={{ marginBottom: "15px", backgroundColor: "#3fb550" }}
              >
                {jeziki[authContext.language].textVpisGoogle}
              </Button>
            </Box>
          </Box>
        </Container>
      ) : (
        <Container
          component="main"
          maxWidth="xs"
          style={{ backgroundColor: "white", opacity: 0.95 }}
          sx={{ marginBottom: { xs: "60px", md: "0px" } }}
        >
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }} style={{ backgroundColor: "#3fb550" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {jeziki[authContext.language].textUstvari}
            </Typography>
            {error && <Alert severity="error">{error}</Alert>}
            <Box component="form" noValidate onSubmit={handleCreateUserWithEmail} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    /* ref={emailRefCreate} */
                    onChange={(value) => {
                      setEmailValue(value.target.value);
                    }}
                    required
                    fullWidth
                    id="email"
                    label={jeziki[authContext.language].textEmail}
                    name="email"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={(value) => {
                      setPasswordValue(value.target.value);
                    }}
                    required
                    fullWidth
                    name="password"
                    label={jeziki[authContext.language].textPassword}
                    type="password"
                    id="password"
                    autoComplete="new-password"
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={loading}
                sx={{ mt: 3, mb: 2 }}
                //onClick={() => handleCreateUserWithEmail()}
                style={{ marginTop: "10px", marginBottom: "10px", backgroundColor: "#3fb550" }}
              >
                {jeziki[authContext.language].textUstvari}
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link href="#" variant="body2" onClick={() => setHaveAccount(true)}>
                    {jeziki[authContext.language].textImamProfil}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      )}
      <Container sx={{ marginBottom: { xs: "200px", lg: "0px" } }}></Container>
    </ThemeProvider>
  );
}
