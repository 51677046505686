import * as React from "react";
import { AuthContext } from "./../src/auth";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import MenuIcon from "@mui/icons-material/Menu";
import logoMGRT from "./Logotipi/MGRT_SLO-velik.jpg";
import logoPMSNS from "./Logotipi/mmonkcolor.jpg";
import logoMURAVIDEKRE from "./Logotipi/muravidekre_logo.jpg";
import logoMURAVIDEKRE2 from "./Logotipi/muravidekre_logo_2.jpg";
import UserInfo from "./components/UserInfo";
import LanguageSelect from "./components/LanguageSelector";
import "flag-icon-css/css/flag-icon.min.css";

export default function ButtonAppBar() {
  const authContext = React.useContext(AuthContext);
  const handleChange = (panel) => (event, isExpanded) => {
    authContext.setLanguage(panel);
  };
  return (
    <Box sx={{ flexGrow: 1, display: { xs: "block", lg: "none" } }}>
      <AppBar position="fixed" color="default" sx={{ top: "auto", bottom: 0 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={4} textAlign="center">
              <a target="__blank" href="https://www.gov.si/drzavni-organi/ministrstva/ministrstvo-za-gospodarski-razvoj-in-tehnologijo/">
                <img alt="MGRTLogo" src={logoMGRT} style={{ maxHeight: "50px", paddingRight: "10px", margin: "5px" }} />
              </a>
            </Grid>
            <Grid item xs={6} sm={4} textAlign="center">
              <a target="__blank" href="http://www.muravidek.si/?lang=sl">
                <img alt="PMSNSLogo" src={logoPMSNS} style={{ maxHeight: "50px", paddingRight: "10px", margin: "5px" }} />
              </a>
            </Grid>
            <Grid item xs={6} sm={4} textAlign="center">
              <a target="__blank" href="https://www.muravidek.re/">
                <img alt="MURAVIDEKRELogo" src={logoMURAVIDEKRE} style={{ maxHeight: "50px", margin: "5px" }} />
              </a>
            </Grid>
          </Grid>
        </Box>
      </AppBar>
    </Box>
  );
}
