export default {
  si: {
    ime: "Slovenščina",
    imeIgre: "Iskanje parov",
    tipIgre: "Spominska igra",
    navodila:
      "Igro sestavlja 20 parov kartic. Cilj igre je zbrati čim več parov. Kartice položimo na mizo s sličicami obrnjenimi navzdol, nato jih premešamo. Določimo igralca, ki bo igro začel, nato nadaljujemo v smeri urinega kazalca. Igralec, ki je na vrsti, obrne dve kartici tako, da ju vidijo tudi soigralci. Če je na obeh karticah enaka slika, ju vzame k sebi in nadaljuje igro. Če pri iskanju para ni bil uspešen, položi obe kartici na prvotno mesto. Z igro nadaljuje naslednji igralec. Zmaga igralec, ki je zbral največje število parov.",
    textVpis: "Vpiši se",
    textVpisGoogle: "Vpiši se z Google",
    jezik: "Izbira jezika",
    textEmail: "Elektronski naslov",
    textPassword: "Geslo",
    textPozabljenoGeslo: "Pozabljeno geslo?",
    textUstvariNovProfil: "Ustvari nov profil",
    textUstvari: "Ustvari nov profil",
    textImamProfil: "Že imaš profil? Vpiši se",
    textIzpisiSe: "Izpiši se",
    textZacni: "Začni",
    textVelikostPolja: "Število kartic",
    textNazaj: "Nazaj",
    textPonovno: "Začni znova",
    textCas: "Preostal čas",
    textRezultat: "Rezultat",
    textAli: "ALI",
    textLobby: "Čakalnica",
    errorFaieldToCreateAccount: "Napaka pri kreiranju novega uporabnika: ",
    errorEmailEmpty: "Polje za elektronski naslov je prazno!",
    errorEmailNotValid: "Elektronski naslov ni veljaven!",
    errorPasswordEmpty: "Polje za geslo je prazno!",
    errorPasswordShort: "Geslo je prekratko! (vsaj 6 znakov)",
    errorUserNotExist: "Uporabnik ne obstaja!",
    errorResetPassword: "Ni mogoče ponastaviti gesla: ",
    emailSent: "Pošta poslana! Prosim preverite svoj poštni predal!",
    leaderboard: "Lestvica najboljših",
    textAktivneIgre: "Aktivne igre",
    textUstvariNovoIgro: "Ustvari novo igro",
    textIzbranaIgra: "Izbrana igra",
    textIgralci: "Igralci",
    textPridruziSe: "Pridruži se",
  },
  hu: {
    ime: "Magyar",
    imeIgre: "Párkereső",
    tipIgre: "Memóriajáték",
    navodila:
      "A játék 20 pár kártyát tartalmaz. A célja az azonos képpárok megtalálása. A kártyákat a képekkel lefele az asztalra tesszük, megkeverjük. Kijelöljük a kezdő játékost, majd az óramutató járásával megegyező irányban folytatjuk a játékot. A soron lévő játékos két kártyát fordít fel úgy, hogy azt játékostársai is látják. Ha mindkettőn egyforma kép található, akkor elveszi a két kártyalapot és folytatja a játékot. Ha a képpár keresésében nem jár sikerrel, mindkét kártyát képpel lefele visszateszi eredeti helyére, és a játékot a szomszédos játékos folytatja. Az a játékos győz, akinek a legtöbb képpárt sikerül összegyűjtenie.",
    textVpis: "Bejelentkezés",
    textVpisGoogle: "Google bejegyzés",
    jezik: "Nyelvválasztás",
    textEmail: "E-mail cím",
    textPassword: "Jelszó",
    textPozabljenoGeslo: "Elfeledett jelszó",
    textUstvariNovProfil: "Nincs még saját profilja? Hozzon létre egy új profilt!",
    textUstvari: "Új profil létrehozása",
    textImamProfil: "Van már profilja?",
    textIzpisiSe: "Kijelentkezés",
    textZacni: "Kezdés",
    textVelikostPolja: "Játékkártyák száma",
    textNazaj: "Vissza",
    textPonovno: "Kezdje újra",
    textCas: "Hátralévő idő",
    textRezultat: "Eredmény",
    textAli: "VAGY",
    textLobby: "Váróterem",
    errorFaieldToCreateAccount: "Nem sikerült létrehoznia a profilját",
    errorEmailEmpty: "Az e-mail mező üres",
    errorEmailNotValid: "Érvénytelen E-mail cím ",
    errorPasswordEmpty: "A jelszó mező üres",
    errorPasswordShort: "A jelszó túl rövid! (legalább 6 jel)",
    errorUserNotExist: "A felhasználó nem létezik!",
    errorResetPassword: "Nem lehetséges a jelszót beálitása: ",
    emailSent: "Email elküldve! Kérjük, ellenőrizze a postaládáját!",
    leaderboard: "Ranglista",
    textAktivneIgre: "Aktív játékok",
    textUstvariNovoIgro: "Új játék létrehozása",
    textIzbranaIgra: "Kiválasztott játék",
    textIgralci: "Játékosok",
    textPridruziSe: "Csatlakozzon",
  },
  en: {
    ime: "English",
    imeIgre: "Find the Pair",
    tipIgre: "A Memory Game",
    navodila:
      "The game consists of 20 cards. The aim is to find the matching picture pairs. The cards need to be laid out onto the table with the picture side down, then we mix them. We appoint the starting player, then continue clockwise. The player whose turn it is chooses two cards and turns them over so every other player can see them too. If the cards depict matching pictures, the player takes them and continues the game. If his search for pairs turns out to be unsuccessful in the next round, he puts them back in their original place. The game is then continued by the next player. The winner is the player with the largest number of matching picture pairs.",
    textVpis: "Login",
    textVpisGoogle: "Login with Google",
    jezik: "Language select",
    textEmail: "Email Address",
    textPassword: "Password",
    textPozabljenoGeslo: "Forgot password?",
    textUstvariNovProfil: "Don't have an account? Sign up",
    textUstvari: "Sign up",
    textImamProfil: "Alredy have an account? Sign in",
    textIzpisiSe: "Sign out",
    textZacni: "Go",
    textVelikostPolja: "Number of cards",
    textNazaj: "Back",
    textPonovno: "Restart",
    textCas: "Time remaining",
    textRezultat: "Score",
    textAli: "OR",
    textLobby: "Lobby",
    errorFaieldToCreateAccount: "Failed to create an account: ",
    errorEmailEmpty: "Email field Empty!",
    errorEmailNotValid: "Email not valid!",
    errorPasswordEmpty: "Password empty!",
    errorPasswordShort: "Password too short! (at least 6 characters)",
    errorUserNotExist: "User doesn't exist!",
    errorResetPassword: "Cannot reset your password: ",
    emailSent: "Email sent! Please check your inbox!",
    leaderboard: "Leaderboard",
    textAktivneIgre: "Active games",
    textUstvariNovoIgro: "Create new game",
    textIzbranaIgra: "Selected game",
    textIgralci: "Players",
    textPridruziSe: "Join",
  },
  de: {
    ime: "Deutsch",
    imeIgre: "Finde das Paar",
    tipIgre: "Ein Gedächtnisspiel",
    navodila:
      "Das Spiel enthält 20 Karten. Das Ziel des Spiels ist es, Bildpaare zu finden. Die Karten müssen zuerst mit dem Bild nach unten auf den Tisch gelegt werden, dann werden sie gemischt. Es wird festgesetzt, wer das Spiel beginnen soll. Danach geht es im Uhrzeigersinn weiter. Wer an der Reihe ist, darf nacheinander immer zwei Karten aufdecken, und zwar so, dass jeder Spieler seine Karten sehen kann. Sind es Karten mit zwei gleichen Bildern, darf sich der Spieler diese beiden Karten nehmen und noch zwei weitere aufdecken. Wenn er verschiedene Karten aufdeckt, legt er sie zurück und das Spiel wird vom nächsten Spieler fortgesetzt. Wer am Schluss die meisten Kartenpaare besitzt, ist der Gewinner der Spielrunde.",
    textVpis: "Anmeldung (melde dich für das Spiel an)",
    textVpisGoogle: "Googlev Anmeldung",
    jezik: "Sprachauswahl",
    textEmail: "E-mail-Adresse",
    textPassword: "Passwort",
    textPozabljenoGeslo: "Passwort wiederherstellen (vergessenes Passwort)",
    textUstvariNovProfil: "Ein neues Profil herstellen",
    textUstvari: "Ein neues Profil herstellen",
    textImamProfil: "Hast du bereits ein Profil? Melde dich an",
    textIzpisiSe: "Abmeldung ",
    textZacni: "Starte das Spiel",
    textVelikostPolja: "Spielfeld (das Spielbrett grösse)",
    textNazaj: "Zuruck",
    textPonovno: "Spiel neu starten",
    textCas: "Verbleibende Spielzeit",
    textRezultat: "Das Ergebnis",
    textAli: "ODER",
    textLobby: "Wartezimmer",
    errorFaieldToCreateAccount: "Benutzer konnte nicht erstellt werden: ",
    errorEmailEmpty: "E-Mail-Adresse ist leer",
    errorEmailNotValid: "E-Mail-Adresse ist nicht bekannt",
    errorPasswordEmpty: "Passwortfeld ist leer",
    errorPasswordShort: "Das Passwort ist zu kurz (mindestens 6 Zeichen)",
    errorUserNotExist: "Benutzer existiert nicht",
    errorResetPassword: "Ihr Passwort kann nicht zurückgesetz werden!",
    emailSent: "E-Mail versendet! Bitte dein Posteingang überprüfen!",
    leaderboard: "Bestenliste",
    textAktivneIgre: "Aktiv Spiele",
    textUstvariNovoIgro: "Erstelle ein neues Spiel",
    textIzbranaIgra: "Ausgewähltes Spiel",
    textIgralci: "Spielspieler",
    textPridruziSe: "Nimm am Spiel teil",
  },
};
