import React, { useState, useEffect } from "react";
import { Grid, Card, CardContent, FormControl, RadioGroup, FormControlLabel, FormLabel, Radio, Checkbox, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { store } from "../../store";
import Leaderboard from "../Leaderboard";
import jeziki from "./../../jeziki";
import { AuthContext } from "./../../auth";
import { database } from "./../../firebase";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Container from "@mui/material/Container";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import service from "./../service";
import { shuffle } from "../../helpers/shuffle";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";

export default function GameOptions() {
  const authContext = React.useContext(AuthContext);
  const history = useHistory();
  const { state, dispatch } = React.useContext(store);
  const [gamesize, setGamesize] = React.useState(state.options ? state.options.grid : "4*5");
  const [isVisible, setIsVisible] = React.useState(state.options ? state.options.isVisible : false);
  const [games, setGames] = useState([]);
  const [currentTutorial, setCurrentTutorial] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [gameCreated, setGameCreated] = useState(false);

  useEffect(() => {
    service.getAll().on("value", onDataChange);
    console.log("startup");
    return () => {
      service.getAll().off("value", onDataChange);
      console.log("cleanup");
      if (gameCreated) database.ref(`games/${gameCreated}`).remove();
    };
  }, []);

  const refreshList = () => {
    setCurrentTutorial(null);
    setCurrentIndex(-1);
  };

  useEffect(() => {
    if (gameCreated) {
      let activeGame = games.find((game) => game.key === gameCreated);
      if (activeGame) {
        if (activeGame.started) {
          dispatch({
            type: "GAME_OPTIONS",
            payload: { grid: gamesize, isVisible, gameId: gameCreated },
          });
          dispatch({
            type: "RESET",
          });
          history.push(`/game-1/${gameCreated}`);
        }
      }
    }
  });

  const onDataChange = (items) => {
    let games = [];
    items.forEach((item) => {
      let key = item.key;
      let data = item.val();
      games.push({
        key: key,
        title: data.creator,
        grid: data.grid,
        gameRef: data.gameRef,
        creator: data.creator,
        creatorUsername: data.creatorUsername,
        photoUrl: data.photoUrl,
        players: data.players,
        started: data.started,
      });
    });
    setGames(games);
    //console.log(games);
  };

  const startGame = () => {
    //console.log("asd");
    dispatch({
      type: "GAME_OPTIONS",
      payload: { grid: gamesize, isVisible, gameId: gameCreated },
    });
    dispatch({
      type: "RESET",
    });
    database.ref(`games/${gameCreated}`).update({
      started: true,
    });
    history.push(`/game-1/${gameCreated}`);
    authContext.setActiveGame(gameCreated);
  };
  const deleteGame = (gameCreated) => {
    database.ref(`games/${gameCreated}`).remove();
    dispatch({
      type: "GAME_OPTIONS",
      payload: { grid: gamesize, isVisible, gameId: "" },
    });
    setGameCreated(false);
  };

  const activeGames = () => {
    /* return database
      .ref("games")
      .once("value")
      .then((snapshot) => {
        snapshot.forEach((igra) => {
          console.log(igra.val());
          let gameData = igra.val();
          return (
            <Card>
              <h1>Creator: {gameData.creatorUsername}</h1>
              <h1>Grid: {gameData.grid}</h1>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt={gameData.displayName} src={gameData.photoURL ? gameData.photoURL : ""} />
                </ListItemAvatar>
                <ListItemText
                  primary="Oui Oui"
                  secondary={
                    <React.Fragment>
                      <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.primary">
                        Sandra Adams
                      </Typography>
                      {" — Do you have Paris recommendations? Have you ever…"}
                    </React.Fragment>
                  }
                />
              </ListItem>
            </Card>
          );
        });
      }); */
  };
  const pridruziSeIgri = (gameRef) => {
    let userName = authContext.user.displayName
      ? authContext.user.displayName
      : authContext.user.email.substring(0, authContext.user.email.lastIndexOf("@"));
    database.ref(`games/${gameRef}/players/${authContext.user.uid}`).update({
      gameRef: gameRef,
      //playerRef: refPlayer.key,
      uid: authContext.user.uid,
      creatorUsername: userName,
      playerUsername: userName,
      photoUrl: authContext.user.photoURL,
      points: 0,
      isReady: false,
      isTurn: false,
    });
    let playersJoinedArrayCurrent = games.find((game) => game.key === gameRef).players.playersJoinedArray;
    playersJoinedArrayCurrent.push(authContext.user.uid);
    database.ref(`games/${gameRef}/players/playersJoinedArray`).update(playersJoinedArrayCurrent);
    setGameCreated(gameRef);
    authContext.setActiveGame(gameRef);
    /* database
      .ref(`games/${gameRef}/players`)
      .push()
      .then((refPlayer) => {
        database.ref(`games/${gameRef}/players`).push({
          gameRef: gameRef,
          playerRef: refPlayer.key,
          uid: authContext.user.uid,
          playerUsername: userName,
          photoUrl: authContext.user.photoURL,
          points: 0,
          isReady: false,
          isTurn: false,
        });
        setGameCreated(gameRef);
        authContext.setActiveGame(gameRef);
      }); */
  };
  const leaveGame = (gameRef) => {
    let userName = authContext.user.displayName
      ? authContext.user.displayName
      : authContext.user.email.substring(0, authContext.user.email.lastIndexOf("@"));
    let playersJoinedArrayCurrent = games.find((game) => game.key === gameRef).players.playersJoinedArray;
    playersJoinedArrayCurrent = playersJoinedArrayCurrent.filter((e) => e !== authContext.user.uid);
    database.ref(`games/${gameRef}/players/${authContext.user.uid}`).remove();
    //database.ref(`games/${gameRef}/players/playersJoinedArray`).set(false);
    database.ref(`games/${gameRef}/players/playersJoinedArray`).set(playersJoinedArrayCurrent);
    setGameCreated(false);
    authContext.setActiveGame(false);
    /* let userName = authContext.user.displayName
      ? authContext.user.displayName
      : authContext.user.email.substring(0, authContext.user.email.lastIndexOf("@"));
      database
      .ref(`games/${gameRef}/players`)
      .push()
      .then((refPlayer) => {
        database.ref(`games/${gameRef}/players`).push({
          gameRef: gameRef,
          playerRef: refPlayer.key,
          uid: authContext.user.uid,
          playerUsername: userName,
          photoUrl: authContext.user.photoURL,
          points: 0,
          isReady: false,
          isTurn: false,
        });
        setGameCreated("");
      }); */
  };
  const createGame = () => {
    let userName = authContext.user.displayName
      ? authContext.user.displayName
      : authContext.user.email.substring(0, authContext.user.email.lastIndexOf("@"));
    let size = eval(gamesize) / 2;
    let i = 0;
    let data = [...state.data.slice(0, size), ...state.data.slice(0, size)].map((item) => {
      return { ...item, id: i++ };
    });
    data = shuffle(data);
    database
      .ref("games")
      .push()
      .then((ref) => {
        setGameCreated(ref.key);
        database.ref(`games/${ref.key}`).update({
          gameRef: ref.key,
          creator: authContext.user.uid,
          photoUrl: authContext.user.photoURL,
          creatorUsername: userName,
          grid: gamesize,
          started: false,
          gridData: data,
          flipped: false,
          //playersJoinedArray: [authContext.user.uid],
        });
        database.ref(`games/${ref.key}/players/playersJoinedArray`).update([authContext.user.uid]);
        database.ref(`games/${ref.key}/players/${authContext.user.uid}`).update({
          gameRef: ref.key,
          //playerRef: refPlayer.key,
          uid: authContext.user.uid,
          creatorUsername: userName,
          playerUsername: userName,
          photoUrl: authContext.user.photoURL,
          points: 0,
          isReady: false,
          isTurn: true,
        });
        /* database
          .ref(`games/${ref.key}/players`)
          .push()
          .then((refPlayer) => {
            database.ref(`games/${ref.key}/players`).push({
              gameRef: ref.key,
              playerRef: refPlayer.key,
              uid: authContext.user.uid,
              creatorUsername: userName,
              playerUsername: userName,
              photoUrl: authContext.user.photoURL,
              points: 0,
              isReady: false,
              isTurn: false,
            });
          }); */
      });
  };

  return (
    <>
      <Leaderboard></Leaderboard>
      <Grid container direction="row" justifyContent="center" alignItems="center" style={{ height: "80vh" }}>
        {games.length > 0 && !gameCreated ? (
          <Paper
            elevation={8}
            sx={{ marginRight: { xs: "none", md: "5px" } }}
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "20px",
              width: "100%",
              padding: "10px",
              gap: "10px",
            }}
          >
            <Typography component="h1" variant="h5">
              {jeziki[authContext.language].textAktivneIgre}
            </Typography>

            {games.length > 0
              ? games.map((game, index) => {
                  if (game.started) return;
                  return (
                    <Card
                      key={index + "games"}
                      className="leader-items"
                      style={{
                        margin: "5px",
                        padding: "10px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Avatar alt={game.creatorUsername} src={game.photoUrl} />
                      <div>
                        <h3>{game.creatorUsername}</h3>
                        <hr />
                        <p>
                          {jeziki[authContext.language].textVelikostPolja} : {eval(game.grid)}
                        </p>
                      </div>
                      <div>
                        <h3>{jeziki[authContext.language].textIgralci}</h3>
                        <hr />
                        {game.players
                          ? Object.keys(game.players).map(function (key, index) {
                              return <p key={key + "playersGame" + game.title}>{game.players[key].playerUsername}</p>;
                            })
                          : ""}
                      </div>
                      <Grid
                        item
                        //className={"list-group-item " + (index === currentIndex ? "active" : "")}
                        //onClick={() => setActiveTutorial(tutorial, index)}
                        key={index}
                      >
                        <Button
                          style={{ backgroundColor: "#3fb550" }}
                          fullWidth
                          variant="contained"
                          onClick={() => pridruziSeIgri(game.gameRef)}
                          sx={{ mt: 3, mb: 2 }}
                          startIcon={<VideogameAssetIcon />}
                        >
                          {jeziki[authContext.language].textPridruziSe}
                        </Button>
                      </Grid>
                    </Card>
                  );
                })
              : ""}
          </Paper>
        ) : (
          ""
        )}
        {games.find((game) => game.key === gameCreated) ? (
          <Grid item>
            <Card style={{ padding: "5px" }}>
              <CardContent>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <Typography component="h1" variant="h5">
                    {jeziki[authContext.language].textIzbranaIgra}
                  </Typography>
                  <div style={{ height: "20px", width: "100%" }}></div>
                  <Avatar
                    alt={games.find((game) => game.key === gameCreated).creatorUsername}
                    src={games.find((game) => game.key === gameCreated).photoUrl}
                  />
                  <div style={{ height: "20px", width: "100%" }}></div>
                  <div style={{ width: "100%" }}>
                    <h3>{games.find((game) => game.key === gameCreated).creatorUsername}</h3>
                    <hr />
                    <p>
                      {jeziki[authContext.language].textVelikostPolja} : {eval(games.find((game) => game.key === gameCreated).grid)}
                    </p>
                  </div>
                  <div style={{ height: "20px", width: "100%" }}></div>
                  <div style={{ width: "100%" }}>
                    <h3>{jeziki[authContext.language].textIgralci}</h3>
                    <hr />
                    {games.find((game) => game.key === gameCreated).players
                      ? Object.keys(games.find((game) => game.key === gameCreated).players).map(function (key, index) {
                          return (
                            <p key={key + "playersGame" + games.find((game) => game.key === gameCreated).title}>
                              {games.find((game) => game.key === gameCreated).players[key].playerUsername}
                            </p>
                          );
                        })
                      : ""}
                  </div>
                  <div style={{ height: "30px", width: "100%" }}></div>
                  {games.find((game) => game.key === gameCreated).creator === authContext.user.uid ? (
                    <>
                      <Button color="secondary" variant="contained" onClick={startGame} style={{ backgroundColor: "#3fb550", marginBottom: "10px" }}>
                        {jeziki[authContext.language].textZacni}
                      </Button>
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => deleteGame(gameCreated)}
                        style={{ backgroundColor: "#3fb550", marginBottom: "10px" }}
                      >
                        {jeziki[authContext.language].textNazaj}
                      </Button>
                    </>
                  ) : (
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => leaveGame(gameCreated)}
                      style={{ backgroundColor: "#3fb550", marginBottom: "10px" }}
                    >
                      {jeziki[authContext.language].textNazaj}
                    </Button>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ) : (
          <Grid item sx={{ marginBottom: { xs: "60px" } }}>
            <Card style={{ padding: "5px" }}>
              <Typography component="h1" variant="h5">
                {jeziki[authContext.language].textUstvariNovoIgro}
              </Typography>
              <CardContent>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <FormControl component="fieldset" style={{ margin: "10px" }}>
                    <FormLabel component="legend">{jeziki[authContext.language].textVelikostPolja}</FormLabel>
                    <RadioGroup
                      aria-label="game"
                      name="game"
                      value={gamesize}
                      onChange={(event) => {
                        setGamesize(event.target.value);
                      }}
                    >
                      <FormControlLabel value="4*4" control={<Radio />} label="16" />
                      <FormControlLabel value="4*5" control={<Radio />} label="20" />
                      <FormControlLabel value="6*5" control={<Radio />} label="30" />
                      <FormControlLabel value="8*5" control={<Radio />} label="40" />
                    </RadioGroup>
                  </FormControl>
                  {/* <Button color="secondary" variant="contained" onClick={startGame} style={{ backgroundColor: "#3fb550" }}>
                  {jeziki[authContext.language].textZacni}
                </Button> */}
                  <Button color="secondary" variant="contained" onClick={createGame} style={{ backgroundColor: "#3fb550" }}>
                    {jeziki[authContext.language].textZacni}
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
      <Container sx={{ marginBottom: { xs: "200px", lg: "0px" } }}></Container>
    </>
  );
}
