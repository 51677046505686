/* eslint-disable no-eval */
import React from "react";
import GameCard from "./GameCard";
import { store } from "../../store";
import { shuffle } from "../../helpers/shuffle";
import { useHistory } from "react-router-dom";
import { Popup } from "./Popup";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import Card from "@mui/material/Card";
import jeziki from "./../../jeziki";
import { AuthContext } from "../../auth";
import { database } from "./../../firebase";
import service from "./../service";
import Avatar from "@mui/material/Avatar";

export default function GameGrid({ match }) {
  const { state, dispatch } = React.useContext(store);
  const [content, setContent] = React.useState([]);
  const [flipped, setFlipped] = React.useState([]);
  const [solved, setSolved] = React.useState([]);
  const [disabled, setDisabled] = React.useState(false);
  const [notMatch, setNotMatch] = React.useState(false);
  const [cardDimensions, setCardDimensions] = React.useState(150);
  const authContext = React.useContext(AuthContext);
  const [sounds, setSounds] = React.useState(authContext.sounds);
  const [playerArray, setPlayerArray] = React.useState([]);
  const [playerData, setPlayerData] = React.useState({});
  const [isNotMyTurn, setIsNotMyTurn] = React.useState(true);
  const [isFinished, setIsFinished] = React.useState(false);
  const audioOpen = new Audio();
  audioOpen.src = process.env.PUBLIC_URL + "/zvoki/open.wav";
  const audioMatch = new Audio();
  audioMatch.src = process.env.PUBLIC_URL + "/zvoki/match.mp3";

  React.useEffect(() => {
    setSounds(authContext.sounds);
  }, [authContext]);

  React.useEffect(() => {
    service.getAllForGame(authContext.activeGame).on("value", onDataChange);
    return () => {
      service.getAllForGame(authContext.activeGame).off("value", onDataChange);
    };
  }, []);
  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }
  /* React.useEffect(() => {
    database.ref(`games/${authContext.activeGame}`).on("value", (snapshot) => {
      setContent(snapshot.val().gridData);
    });
  }, []); */

  const onDataChange = (gameData) => {
    if (gameData.val().flipped) {
      if (sounds) {
        audioOpen.play();
      }
      setFlipped(gameData.val().flipped);
    } else {
      delay(1000).then(() => {
        setFlipped([]);
      });
    }
    if (gameData.val().solved) setSolved(gameData.val().solved);
    setPlayerArray(gameData.val().players.playersJoinedArray);
    setIsNotMyTurn(!gameData.val().players[authContext.user.uid].isTurn);
    setDisabled(!gameData.val().players[authContext.user.uid].isTurn);
    setPlayerData(gameData.val().players);
    setContent(gameData.val().gridData);
    if (gameData.val().solved) if (gameData.val().solved.length === eval(gameData.val().grid)) setIsFinished(true);
  };

  /* React.useEffect(() => {
    database.ref(`games/${authContext.activeGame}/flipped`).on("value", (data) => {
      //console.log(data);
      //console.log(data.val());
      console.log("Before");
      console.log(flipped);
      console.log(data.val());
      if (flipped !== data.val()) {
        if (data.val()) {
          if (authContext.sounds) {
            audioOpen.play();
          }
          setFlipped(data.val());
        }
      }
      console.log("After");
      console.log(flipped);
      console.log(data.val());
    });
    return database.ref(`games/${authContext.activeGame}/flipped`).off("value");
  }); */
  const history = useHistory();
  function flip(id) {
    if (solved.includes(id)) return;
    console.log("DISABLED: " + disabled);
    setDisabled(true);
    if (flipped.length === 0) {
      //if the first card is flipped
      database.ref(`games/${authContext.activeGame}/flipped`).update([id]);
      setFlipped([id]);
      setDisabled(false);
    } else {
      //if the second card is flipped
      if (sameCardClicked(id)) {
        //setDisabled(false);
        return;
      } //and not the same as the first one
      //const updates = {};

      database.ref(`games/${authContext.activeGame}/flipped`).update([...flipped, id]);
      //updates[`games/${authContext.activeGame}/flipped`] = [...flipped, id];
      setFlipped([...flipped, id]);
      if (isMatch(id)) {
        // if the two cards are matched //success
        if (authContext.sounds) {
          audioMatch.play();
        }
        setSolved([...solved, ...flipped, id]);

        //database.ref(`games/${authContext.activeGame}/flipped`).remove();
        database.ref(`games/${authContext.activeGame}/flipped`).set(false);
        //updates[`games/${authContext.activeGame}/flipped`] = false;

        database.ref(`games/${authContext.activeGame}/solved`).update([...solved, ...flipped, id]);
        //updates[`games/${authContext.activeGame}/solved`] = [...solved, ...flipped, id];

        database.ref(`games/${authContext.activeGame}/players/${authContext.user.uid}/isTurn`).set(false);
        //updates[`games/${authContext.activeGame}/players/${authContext.user.uid}/isTurn`] = false;
        let currentPoints = playerData[authContext.user.uid].points;
        currentPoints = currentPoints + 5;

        database.ref(`games/${authContext.activeGame}/players/${authContext.user.uid}/points`).set(currentPoints);
        //updates[`games/${authContext.activeGame}/players/${authContext.user.uid}/points`] = currentPoints;
        let allPlayers = playerArray;
        let currentPlayer = authContext.user.uid;
        let currentIndex = allPlayers.indexOf(currentPlayer);
        let nextIndex = currentIndex % allPlayers.length;
        currentPlayer = allPlayers[nextIndex];

        database.ref(`games/${authContext.activeGame}/players/${currentPlayer}/isTurn`).set(true);
        //updates[`games/${authContext.activeGame}/players/${currentPlayer}/isTurn`] = false;

        setFlipped([]);
        //setDisabled(false);
        dispatch({
          type: "SUCCESS",
        });
      } else {
        //database.ref(`games/${authContext.activeGame}/flipped`).remove();
        database.ref(`games/${authContext.activeGame}/flipped`).set(false);
        //updates[`games/${authContext.activeGame}/flipped`] = false;

        database.ref(`games/${authContext.activeGame}/players/${authContext.user.uid}/isTurn`).set(false);
        //updates[`games/${authContext.activeGame}/players/${authContext.user.uid}/isTurn`] = false;

        let currentPoints = playerData[authContext.user.uid].points;
        currentPoints = currentPoints - 1;
        database.ref(`games/${authContext.activeGame}/players/${authContext.user.uid}/points`).set(currentPoints);
        //updates[`games/${authContext.activeGame}/players/${authContext.user.uid}/points`] = currentPoints;

        let allPlayers = playerArray;
        let currentPlayer = authContext.user.uid;
        let currentIndex = allPlayers.indexOf(currentPlayer);
        let nextIndex = (currentIndex + 1) % allPlayers.length;
        currentPlayer = allPlayers[nextIndex];
        database.ref(`games/${authContext.activeGame}/players/${currentPlayer}/isTurn`).set(true);
        //updates[`games/${authContext.activeGame}/players/${currentPlayer}/isTurn`] = true;

        dispatch({
          type: "FAILURE",
        });
        // if the two cards are not matched //failure

        setNotMatch(true);
      }
      /* delay(1000).then(() => {
        //database.ref().update(updates);
      }); */
    }
  }
  function isMatch(id) {
    const oldCard = content.find((card) => card.id === flipped[0]);
    const newCard = content.find((card) => card.id === id);

    return oldCard.name === newCard.name;
  }
  function sameCardClicked(id) {
    return flipped.includes(id);
  }
  React.useEffect(() => {
    if (
      /* eval(state.options ? state.options.grid : 0) === state.stats.trys || */
      state.stats.success ===
      eval(state.options ? state.options.grid : 0) / 2
    ) {
      dispatch({
        type: "OVER",
      });
    }
  }, [content.length, dispatch, state.options, state.stats.success, state.stats.trys]);
  React.useEffect(() => {
    if (!state.options) history.push("/game-1-options");
  }, [history, state.options]);

  React.useEffect(() => {
    if (state.options) {
      /*  // eslint-disable-next-line no-eval
      let size = eval(state.options.grid) / 2;
      let i = 0;
      let data = [...state.data.slice(0, size), ...state.data.slice(0, size)].map((item) => {
        return { ...item, id: i++ };
      }); */
      //setContent(shuffle(data));
    }
  }, [state.data, state.options]);
  React.useEffect(() => {
    if (!notMatch) return;
    const to = setTimeout(() => {
      setFlipped([]);
      setDisabled(false);
      setNotMatch(false);
    }, 500);

    return () => {
      clearTimeout(to);
    };
  }, [notMatch]);
  function restartGame() {
    if (disabled) return;
    if (state.options && state.options.isVisible) {
      setDisabled(true);
      setTimeout(() => {
        setSolved(content.map((i) => i.id));
      }, 500);
      setTimeout(() => {
        setSolved([]);
        setDisabled(false);
      }, 2000);
    }
    setSolved([]);
    setContent(shuffle(content));
    setFlipped([]);
    dispatch({
      type: "RESET",
    });
  }
  React.useEffect(() => {
    let to;
    if (state.options && state.options.isVisible) {
      setDisabled(true);
      setSolved(content.map((i) => i.id));
      to = setTimeout(() => {
        setSolved([]);
        setDisabled(false);
      }, 1500);
    }
    return () => clearTimeout(to);
  }, [content, state.options]);
  function goback() {
    dispatch({
      type: "RESET",
    });
    database.ref(`games/${state.options.gameId}`).update({
      started: false,
    });
    history.push("/game-1-options");
  }
  const cardContainerRef = React.useRef(null);
  React.useEffect(
    () => {
      let cardClientHeight = Math.round(cardContainerRef.current.clientHeight);
      let cardClientWidth = Math.round(cardContainerRef.current.clientWidth);
      let verticalHorizontal = cardClientHeight > cardClientWidth ? "vertical" : "horizontal";
      if (state.options) {
        let numberOfColumns = state.options.grid.toString().split("*")[0];
        let numberOfRows = state.options.grid.toString().split("*")[1];
        let cardWidth = Math.round(cardClientWidth / numberOfColumns);
        let cardHeight = Math.round(cardClientHeight / numberOfRows);
        if (verticalHorizontal === "vertical") {
          numberOfColumns = state.options.grid.toString().split("*")[1];
          numberOfRows = state.options.grid.toString().split("*")[0];
          cardWidth = Math.round(cardClientWidth / numberOfColumns);
          cardHeight = Math.round(cardClientHeight / numberOfRows);
        }
        let cardSize = Math.round(Math.min(cardWidth, cardHeight));
        setCardDimensions(cardSize - 22);
      }
    } /* , [cardDimensions, state.options] */
  );
  //console.log(playerData);
  return (
    <>
      <Popup restartGame={restartGame} isFinished={isFinished} playerData={playerData}></Popup>
      <div className="game-grid" style={{ height: "100%" }}>
        <div className="game-grid-item stats">
          <div className="leader-items">
            {Object.keys(playerData).map((key, index) => {
              /* console.log(playerData);
              console.log(key);
              console.log(index); */
              if (key !== "playersJoinedArray") {
                return (
                  <Card key={key} className={playerData[key].isTurn ? "leader-item currentPlayer" : "leader-item"}>
                    <h1>{playerData[key].isTurn ? <PlayCircleIcon style={{ color: "#90c858" }} /> : <PauseCircleIcon />}</h1>
                    <Avatar alt={`${playerData[key].playerUsername}`} src={`${playerData[key].photoUrl}`} />
                    <div>
                      <h3 className={authContext.user.uid === playerData[key].uid ? "currentPlayerText" : ""}>{playerData[key].playerUsername}</h3>
                      <hr />
                      <p>{playerData[key].points}</p>
                    </div>
                  </Card>
                  /*  <>
                  
                    <ListItem alignItems="flex-start" key={key}>
                      <ListItemAvatar>
                        <Avatar alt={`${playerData[key].playerUsername}`} src={`${playerData[key].photoUrl}`} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={playerData[key].playerUsername}
                        secondary={
                          <React.Fragment>
                            <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.primary">
                              {playerData[key].points}
                            </Typography>
                            <CircleIcon />
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </> */
                );
              }
            })}
          </div>

          {/* <Box sx={{ display: { xs: "none", lg: "contents" } }}>
            <Button color="primary" variant="contained" onClick={goback} style={{ backgroundColor: "#47713e" }}>
              {jeziki[authContext.language].textNazaj}
            </Button>
            <h1>
              <div style={{ color: "#47713e" }}>
                {jeziki[authContext.language].textCas}:{counter}
              </div>
              {// Trys left:
              //{eval(state.options ? state.options.grid : 0) * 5 - state.stats.trys} }
            </h1>

            <h1 style={{ color: "#47713e" }}>
              {jeziki[authContext.language].textRezultat}:{state.stats.success * 5 - state.stats.failure}
            </h1>
            {// <Button color="secondary" variant="contained" onClick={restartGame} style={{ backgroundColor: "#f99c3f" }}>
              //{jeziki[authContext.language].textPonovno}
            //</Button> }
          </Box> */}
          {/* <Box sx={{ display: { xs: "grid", lg: "none" } }}>
            <Button color="primary" variant="contained" onClick={goback} style={{ backgroundColor: "#47713e" }}>
              {jeziki[authContext.language].textNazaj}
            </Button>
            <h1>
              <div style={{ color: "#47713e" }}>
                {jeziki[authContext.language].textCas}:{counter}
              </div>
              {// Trys left:
              //{eval(state.options ? state.options.grid : 0) * 5 - state.stats.trys} }
            </h1>

            <h1 style={{ color: "#47713e" }}>
              {jeziki[authContext.language].textRezultat}:{state.stats.success * 5 - state.stats.failure}
            </h1>
            {// <Button color="secondary" variant="contained" onClick={restartGame} style={{ backgroundColor: "#f99c3f" }}>
              //{jeziki[authContext.language].textPonovno}
            //</Button> /}
          </Box> */}
        </div>
        <div className="game-grid-item" style={{ maxHeight: "80vh", height: "100%" }}>
          <Box sx={{ display: { xs: "none", lg: "block" } }}>
            <div style={{ width: "30vw", height: "100%" }}>
              <ImageList cols={1} sx={{ width: "100%", height: "100%" }} style={{ overflow: "hidden" }}>
                {/* {Array.from(new Set(solved)).map((item, index) => {
                  if (index % 2 === 0) return;
                  let element = content.find((slika) => slika.id === item);
                  return (
                    <ImageListItem key={element.id}>
                      <img src={`${element.flag}?w=248&fit=crop&auto=format`} alt={element.name} loading="lazy" />
                    </ImageListItem>
                  );
                })} */}
                {solved.length > 0 ? (
                  <ImageListItem key={content.find((slika) => slika.id === solved[solved.length - 1]).id}>
                    <img
                      src={`https://pmsnsspomin.web.app/${
                        content.find((slika) => slika.id === solved[solved.length - 1]).flag
                      }?w=248&fit=crop&auto=format`}
                      alt={content.find((slika) => slika.id === solved[solved.length - 1]).name}
                      loading="lazy"
                    />
                  </ImageListItem>
                ) : (
                  <></>
                )}
              </ImageList>
            </div>
          </Box>
          <div className="card-container" ref={cardContainerRef} style={{ width: "auto", height: "100%" }}>
            {content.map((item) => {
              return (
                <GameCard
                  cardDimensions={cardDimensions}
                  key={item.id}
                  flipped={flipped.includes(item.id) || solved.includes(item.id)}
                  flip={flip}
                  data={item}
                  length={content.length}
                  disabled={isNotMyTurn || disabled} // && disabled}
                  solved={solved.includes(item.id)}
                ></GameCard>
              );
            })}
          </div>
        </div>
      </div>
      <Container sx={{ marginBottom: { xs: "200px", lg: "0px" } }}></Container>
    </>
  );
}
