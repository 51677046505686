import React from "react";
import { store } from "../../store";
import { Card, Button, ButtonGroup, Container } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { db } from "../../firebase";
import Avatar from "@mui/material/Avatar";
import { AuthContext } from "./../../auth";
import jeziki from "../../jeziki";
export const Popup = ({ playerData, isFinished }) => {
  const authContext = React.useContext(AuthContext);
  const { dispatch } = React.useContext(store);
  const [sortedPlayersObject, setSortedPLayersObject] = React.useState([]);
  //console.log(playerData);
  const history = useHistory();
  function goBack() {
    dispatch({
      type: "RESET",
    });
    history.push("/game-1-options");
  }
  function sortByKey(array, key) {
    return array.sort(function (a, b) {
      var x = a[key],
        y = b[key];
      if (typeof x === "string") {
        x = x.toLowerCase();
        y = y.toLowerCase();
        if (!isNaN(x) && !isNaN(y)) {
          x = parseInt(x, 10);
          y = parseInt(y, 10);
        }
      }
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }
  React.useEffect(() => {
    if (isFinished) {
      let tempObject = [];
      // creating sorted object players
      console.log("playerData");
      console.log(playerData);
      Object.keys(playerData).map((key) => {
        if (key !== "playersJoinedArray") tempObject.push(playerData[key]);
      });
      console.log("tempObject");
      console.log(tempObject);
      let sortedByPoints = sortByKey(tempObject.slice(0), "points").reverse();
      console.log("sortedByPoints");
      console.log(sortedByPoints);
      //tempObject = tempObject.sort((a, b) => (a.points < b.points ? 1 : -1));
      setSortedPLayersObject(sortedByPoints);
      // writing score to FIRESTORE DB
      db.collection("score")
        .doc(sortedByPoints[0].uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            if (doc.data().score < sortedByPoints[0].points) {
              db.collection("score")
                .doc(sortedByPoints[0].uid)
                .set({
                  score: sortedByPoints[0].points,
                  name: sortedByPoints[0].playerUsername,
                  photo: sortedByPoints[0].photoUrl ? sortedByPoints[0].photoUrl : null,
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          } else {
            db.collection("score")
              .doc(sortedByPoints[0].uid)
              .set({
                score: sortedByPoints[0].points,
                name: sortedByPoints[0].playerUsername,
                photo: sortedByPoints[0].photoUrl ? sortedByPoints[0].photoUrl : null,
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
    }
  }, [isFinished, playerData]);
  return isFinished ? (
    <div className="popup" style={{ width: "100%" }}>
      <Card className="popup-body" style={{ margin: "auto", width: "100%", position: "absolute" }}>
        <div className="leader-items">
          {sortedPlayersObject.map((item, index) => {
            return (
              <Card key={item.uid} className={index === 0 ? "leader-item current" : "leader-item"}>
                <h1>{index + 1}</h1>
                <Avatar alt={`${item.playerUsername}`} src={`${item.photoUrl}`} />
                <div>
                  <h3 className={authContext.user.uid === item.uid ? "currentPlayerText" : ""}>{item.playerUsername}</h3>
                  <hr />
                  <p>{item.points}</p>
                </div>
              </Card>
            );
          })}
        </div>
        <div className="flex-buttons">
          <ButtonGroup>
            {/* <Button color="secondary" variant="outlined" onClick={restartGame}>
              Play again
            </Button> */}
            <Button color="primary" variant="outlined" onClick={goBack}>
              {jeziki[authContext.language].textNazaj}
            </Button>
          </ButtonGroup>
        </div>
      </Card>
      <Container sx={{ marginBottom: { xs: "200px", md: "0px" } }}></Container>
    </div>
  ) : (
    <></>
  );
};
